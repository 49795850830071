import React from "react";
import { Container } from "react-bootstrap";

/**
 * NotFoundPage returns the page not found error
 * @example
 * NotFoundPage()
 */
const NotFoundPage = () => (
  <Container className="my-5 text-center" data-test="text-container">
    <h1>NOT FOUND</h1>
  </Container>
);

export default NotFoundPage;
